const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://amitchhatbar.com',
  title: 'JS.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Amit Chhatbar',
  role: 'Full Stack Developer',
  description: '',
  resume: '',
  social: {
    linkedin: 'https://www.linkedin.com/in/amitchhatbar11/',
    github: 'https://github.com/amitchhatbar11',
    facebook: 'https://www.facebook.com/amitchhatbar11/',
    twitter: 'https://www.twitter.com/amitchhatbar11/',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Cottage Grill',
    description:
      "A Mumbai based restaurant's website, customers can order food online.",
    stack: [
      'Reactjs',
      'Nodejs',
      'Bootstrap',
      'Expressjs',
      'MySQL',
      'AWS',
      'Nginx',
    ],
    sourceCode: '',
    livePreview: 'https://cottagegrill.in',
  },
  {
    name: 'Veterinary EMR system',
    description:
      'This software provides ERP solution to veterinary hospitals. It has features like, Appointment Scheduling, Canvas draw, Report generation, Doctor dashboard etc.',
    stack: [
      'Reactjs',
      'Nodejs',
      'Bootstrap',
      'Expressjs',
      'AWS',
      'PostgreSQL',
      'MongoDB',
    ],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'Hackernews-Clone',
    description: 'A simple UI clone of hackernews site using hackernews-api.',
    stack: ['Reactjs', 'moment', 'localstorage'],
    sourceCode: 'https://github.com/amitchhatbar11/hackernews-clone',
    livePreview: 'https://hackernews-amit.surge.sh/',
  },
  {
    name: 'Nodejs Boilerplate',
    description:
      'A readymade boilerplate for backend developers which has all basic setup with login and signup code sample using jwt.',
    stack: ['Nodejs', 'Expressjs', 'JWT', 'Sequelize'],
    sourceCode:
      'https://github.com/amitchhatbar11/node-express-sequelize-boilerplate',
    livePreview: '',
  },
  {
    name: 'Pretty Secrets E-commerce brand',
    description:
      'Worked there as a Full-Stack Developer and worked on many features like, website ui revamping, coupon management, admin orders analytics, breadcrumbs, Mobile App enhancement etc.',
    stack: [
      'Reactjs',
      'Nodejs',
      'Bootstrap',
      'Expressjs',
      'MySQL',
      'ReactNative',
      'Pug',
    ],
    sourceCode: '',
    livePreview:
      'https://web.archive.org/web/20190916080004/https://prettysecrets.com/',
  },
  {
    name: 'Height Miners wordpress site',
    description:
      'A website for stock-market advice and blogs. Set it up from scratch, provided Cloudflare, Zoho email, etc integrations.',
    stack: ['Wordpress', 'Cloudflare SSL', 'Zoho email integrations'],
    sourceCode: '',
    livePreview: 'https://heightminers.com/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'JavaScript',
  'Reactjs',
  'Redux',
  'Nodejs',
  'Expressjs',
  'Sequelize',
  'Vuejs',
  'TypeScript',
  'HTML',
  'CSS',
  'SASS/LESS',
  'BootStrap',
  'Tailwind Css',
  'Nginx',
  'AWS EC2',
  'Git',
  'Jest',
  'Enzyme',
  'Wordpress Site setup',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'amitchhatbar11@gmail.com',
}

export { header, about, projects, skills, contact }
